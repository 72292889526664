import Equipments from '@/assets/data/equipments.json';
import { inventoryMapAtom, ownedStudentsMapAtom } from '@/atoms';
import { EQUIPMENT_CATEGORIES, TIERS } from '@/constants/constants';
import { calculateAllStudentEquipmentNeeds } from '@/utils/student-util';
import { useAtom } from 'jotai';
import { Check } from 'lucide-react';
import { useTranslation } from 'react-i18next';

function getEquipmentTierPieceIcon(equipmentCategory, tier) {
  return `/icons/equipments/equipment_icon_${equipmentCategory.toLowerCase()}_tier${tier}_piece.webp`;
}

function InventoryMatrix() {
  const [inventoryMap, setInventoryMap] = useAtom(inventoryMapAtom);
  const { t } = useTranslation();

  return (
    <div className="w-full sm:w-1/2">
      <div className="mb-2 text-xl font-bold"> {t('inventory')} </div>
      <div className="flex h-8">
        <div className="w-20 border-2"></div>
        {TIERS.map((tier) => {
          return (
            <div key={tier} className="w-24 border-2 border-solid border-indigo-600 text-center">
              T{tier}
            </div>
          );
        })}
      </div>

      {EQUIPMENT_CATEGORIES.map((equipmentCategory) => {
        return (
          <div key={equipmentCategory} className="flex h-auto border-b-2">
            <div className="w-20">{t(equipmentCategory)}</div>
            {TIERS.map((tier, index) => {
              const equipment = Equipments.find((equipment) => {
                return equipment.Category === equipmentCategory && equipment.Tier === tier && !equipment.RecipeCost;
              })!;

              return (
                <div key={index} className="flex w-24 flex-col items-center">
                  <span>
                    <img className="w-15 h-12" src={getEquipmentTierPieceIcon(equipmentCategory, tier)} />
                  </span>
                  <span>
                    <input
                      type="number"
                      min={0}
                      className="block w-full rounded-md border-0 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={inventoryMap[equipment.Id] || 0}
                      onChange={(e) => {
                        setInventoryMap((currentInventory) => {
                          return {
                            ...currentInventory,
                            [equipment.Id]: e.target.value,
                          };
                        });
                      }}
                    />
                  </span>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

function EquipmentMatrix() {
  const [ownedStudentsMap] = useAtom(ownedStudentsMapAtom);
  const [inventoryMap] = useAtom(inventoryMapAtom);

  const itemsNeeded = calculateAllStudentEquipmentNeeds(ownedStudentsMap);
  const { t } = useTranslation();

  return (
    <div className="w-full sm:w-1/2">
      <div className="mb-2 text-xl font-bold"> {t('itemsGap')} </div>
      <div className="flex h-8">
        <div className="w-20 border-2"></div>
        {TIERS.map((tier) => {
          return (
            <div key={tier} className="w-24 border-2 border-solid border-indigo-600 text-center">
              T{tier}
            </div>
          );
        })}
      </div>
      {EQUIPMENT_CATEGORIES.map((equipmentCategory) => {
        return (
          <div key={equipmentCategory} className="flex h-auto border-b-2">
            <div className="w-20">{t(equipmentCategory)}</div>
            {TIERS.map((tier, index) => {
              const equipment = Equipments.find((equipment) => {
                return equipment.Category === equipmentCategory && equipment.Tier === tier && !equipment.RecipeCost;
              })!;

              return (
                <div key={index} className="flex w-24 flex-col items-center">
                  <span>
                    <img className="w-15 h-12" src={getEquipmentTierPieceIcon(equipmentCategory, tier)} />
                  </span>
                  <span>
                    {itemsNeeded[equipment.Id] ? (
                      itemsNeeded[equipment.Id] - (inventoryMap[equipment.Id] || 0) <= 0 ? (
                        <Check className="text-sky-600" />
                      ) : (
                        itemsNeeded[equipment.Id] - (inventoryMap[equipment.Id] || 0)
                      )
                    ) : (
                      0
                    )}
                  </span>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default function InventoryPage() {
  return (
    <div className="flex flex-wrap">
      <InventoryMatrix />
      <EquipmentMatrix />
    </div>
  );
}
