import { ownedStudentsMapAtom } from '@/atoms';
import { EQUIPMENT_MAX_LEVELS_BY_REALM, STUDENTS_BY_REALM } from '@/constants/constants';
import { useAtom } from 'jotai';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function getStudentUrl(studentId) {
  return `/avatars/${studentId}.webp`;
}

// 给后面选服务器留个口子
const ALL_STUDENTS = STUDENTS_BY_REALM.CN;

export default function Students() {
  const [searchParam, setSearchParam] = useState('');
  const [ownedStudentsMap, setOwnedStudentsMap] = useAtom(ownedStudentsMapAtom);
  const ownedStudentIds = (Object.keys(ownedStudentsMap) || []).map(Number);

  function searchByStudentName(student) {
    const possibleNames = [student.Name, student.PathName, student.FamilyName, student.PersonalName];
    if (searchParam === '') {
      return true;
    } else {
      return possibleNames.some((possibleName) => possibleName.toLowerCase().includes(searchParam.toLowerCase()));
    }
  }

  const ownedStudents = ALL_STUDENTS.filter((student) => ownedStudentIds.includes(student.Id)).filter(
    searchByStudentName,
  );
  const availableStudents = ALL_STUDENTS.filter((student) => !ownedStudentIds.includes(student.Id)).filter(
    searchByStudentName,
  );
  const { t } = useTranslation();

  return (
    <div>
      <div className="mb-2">
        <label htmlFor="email" className="mb-2 block text-xl font-bold leading-6 text-gray-900">
          {t('searchStudentsInputLabel')}
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="search-students"
            value={searchParam || ''}
            onChange={(event) => {
              const enteredText = event.target.value;
              console.log(enteredText);
              setSearchParam(enteredText);
            }}
            id="search-students"
            className="block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Aru 爱鲁"
          />
        </div>
      </div>

      <div className="mb-2 flex justify-between text-xl font-bold">
        <span>
          {t('ownedStudents')} ({Object.keys(ownedStudents).length})
        </span>
        <button
          type="button"
          className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => {
            setOwnedStudentsMap({});
          }}
        >
          Reset
        </button>
      </div>
      <StudentList students={ownedStudents} setOwnedStudentsMap={setOwnedStudentsMap} />

      <div className="mb-2 mt-4 h-10 text-xl font-bold">
        {t('availableStudents')} ({Object.keys(availableStudents).length})
      </div>
      <StudentList students={availableStudents} setOwnedStudentsMap={setOwnedStudentsMap} />
    </div>
  );
}

function StudentList({ students, setOwnedStudentsMap }) {
  const [ownedStudentsMap] = useAtom(ownedStudentsMapAtom);

  // const itemsNeeded = calculateAllStudentEquipmentNeeds(ownedStudentsMap);
  // console.log('itemsNeeded', itemsNeeded);

  return (
    <ul role="list" className="grid grid-cols-2 gap-6 sm:grid-cols-3 lg:grid-cols-6">
      {students.map((student) => (
        <li
          key={student.Id}
          data-student-id={student.id}
          className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
        >
          <div
            onClick={() => {
              setOwnedStudentsMap((currentMap) => {
                if (currentMap[student.Id]) {
                  const items = { ...currentMap };
                  delete items[student.Id];
                  return items;
                } else {
                  return {
                    ...currentMap,
                    [student.Id]: [1, 1, 1],
                  };
                }
              });
            }}
            className="flex w-full items-center justify-between space-x-6 p-6"
          >
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3 className="truncate text-sm font-medium text-gray-900">{student.Name}</h3>
              </div>
              <p className="mt-1 truncate text-sm text-gray-500">{student.FamilyName}</p>
            </div>
            <img className="h-12 flex-shrink-0 rounded-full bg-gray-300" src={getStudentUrl(student.Id)} alt="" />
          </div>

          <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              {student.Equipment.map((equipment, index) => {
                return (
                  <EquipmentItem
                    key={index}
                    equipmentIndex={index}
                    equipment={equipment}
                    setOwnedStudentsMap={setOwnedStudentsMap}
                    student={student}
                    ownedStudentsMap={ownedStudentsMap}
                  />
                );
              })}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}

function EquipmentItem({ equipment, student, equipmentIndex, setOwnedStudentsMap, ownedStudentsMap }) {
  const { t } = useTranslation();
  let IS_MAX = false;
  if (ownedStudentsMap[student.Id]) {
    IS_MAX = ownedStudentsMap[student.Id][equipmentIndex] === EQUIPMENT_MAX_LEVELS_BY_REALM.zh[equipment];
    // console.log(
    //   ownedStudentsMap,
    //   equipmentIndex,
    //   ownedStudentsMap[student.Id],
    //   ownedStudentsMap[student.Id][equipmentIndex],
    //   EQUIPMENT_MAX_LEVELS_BY_REALM.zh[equipment],
    //   IS_MAX,
    // );
  }

  return (
    <div className="flex w-0 flex-1 text-gray-900 hover:text-blue-500">
      <span
        onClick={() => {
          setOwnedStudentsMap((currentMap) => {
            const currentStudent = currentMap[student.Id];
            if (currentStudent) {
              if (currentStudent[equipmentIndex] < EQUIPMENT_MAX_LEVELS_BY_REALM.zh[equipment]) {
                currentStudent[equipmentIndex] += 1;
              } else {
                currentStudent[equipmentIndex] = 1;
              }
            }
            return {
              ...currentMap,
              [student.Id]: currentStudent,
            };
          });
        }}
        className={
          'relative -mr-px flex inline-flex w-0 flex-1 cursor-pointer select-none flex-col items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-1 text-sm font-semibold ' +
          `${IS_MAX ? ' text-red-800 hover:text-blue-500' : null}`
        }
      >
        <span>{t(equipment)}</span>
        <span>
          {ownedStudentsMap[student.Id]?.[equipmentIndex]}
          <span className="text-xs">{IS_MAX ? `(${t('Max')})` : null}</span>
        </span>
      </span>
    </div>
  );
}
