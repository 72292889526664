import Equipments from '@/assets/data/equipments.json';
import { EQUIPMENT_MAX_LEVELS_BY_REALM, STUDENTS_BY_REALM } from '@/constants/constants';
import { OwnedStudentsMap } from '@/models/models';

const ALL_STUDENTS = STUDENTS_BY_REALM.CN;

// Calculate items needed from the Students we OWN
// This DOES NOT take inventory into consideration!!
export function calculateAllStudentEquipmentNeeds(studentsMap: OwnedStudentsMap) {
  const itemsNeeded = {};
  for (const [studentId, currentLevels] of Object.entries(studentsMap)) {
    const student = ALL_STUDENTS.find((allStudent) => allStudent.Id === +studentId)!;
    const studentEquipmentCategories = student.Equipment;
    currentLevels.forEach((currentLevel, index) => {
      for (
        let tier = currentLevel;
        tier < EQUIPMENT_MAX_LEVELS_BY_REALM.zh[studentEquipmentCategories[index]];
        tier++
      ) {
        const equipment = Equipments.find((equipment) => {
          return (
            equipment.Category === studentEquipmentCategories[index] &&
            equipment.Tier === tier + 1 &&
            equipment.RecipeCost
          );
        })!;

        equipment.Recipe!.forEach((element) => {
          const [itemId, itemCount] = element;
          itemsNeeded[itemId] = (itemsNeeded[itemId] || 0) + itemCount;
        });
      }
    });
  }
  return itemsNeeded;
}
