import CN_STUDENTS from '@/assets/data/cn/students.json';
import { Campaign } from '@/assets/data/stages.json';

// import AllStudents from '@/assets/data/students-china-zh.json';

export const EQUIPMENT_MAX_LEVELS_BY_REALM = {
  // TODO: 校准这个数值
  zh: {
    Hat: 5, // 帽子，confirmed
    Gloves: 5, // 手套，confirmed
    Shoes: 5, // 鞋，confirmed
    Bag: 5, // 背包，confirmed
    Badge: 5, // 徽章，confirmed
    Hairpin: 5, // 发夹，confirmed
    Charm: 5, // 护身符, 14
    Watch: 5, // 手表, 14
    Necklace: 5, // 项链, 14
  },
};
export const EQUIPMENT_CATEGORIES = Object.keys(EQUIPMENT_MAX_LEVELS_BY_REALM.zh);
export const TIERS = [2, 3, 4, 5];
export const MAX_STAGE = 14;

export const CAMPAIGNS = Campaign;

export const STUDENTS_BY_REALM = {
  CN: CN_STUDENTS,
};

// We need a couple of different items, 10001: x, 10002, y, 10003,
