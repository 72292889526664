import { inventoryMapAtom, ownedStudentsMapAtom } from '@/atoms';
import { FarmingCampaign } from '@/models/models';
import { calculateFarmingPlan } from '@/utils/farming-calculator';
import { calculateAllStudentEquipmentNeeds } from '@/utils/student-util';
import { useAtom } from 'jotai';
import { useState } from 'react';

export function Farming() {
  const [ownedStudentsMap] = useAtom(ownedStudentsMapAtom);
  const [inventoryMap] = useAtom(inventoryMapAtom);

  const [farmingCampaigns, setFarmingCampaigns] = useState<FarmingCampaign[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="px-1">
      <div className="flex items-center justify-between">
        <div className="mb-2 text-xl font-bold"> 刷图计算 </div>
        <div>
          <button
            type="button"
            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => {
              setIsLoading(true);
              setTimeout(() => {
                const itemsNeeded = calculateAllStudentEquipmentNeeds(ownedStudentsMap);
                const farmingPlan = calculateFarmingPlan(itemsNeeded, inventoryMap);
                const result: FarmingCampaign[] = [];

                for (const plan in farmingPlan) {
                  if (plan.startsWith('Stage ')) {
                    result.push({ name: plan, count: farmingPlan[plan] });
                  }
                }
                console.log(result[0]);
                const sortedResults = result.sort((a, b) => {
                  return +a.name.split('Stage ')[1].replace('-', '') - +b.name.split('Stage ')[1].replace('-', '');
                });
                setFarmingCampaigns(sortedResults);
                setIsLoading(false);
              }, 0);
            }}
          >
            {isLoading ? '计算中' : '开始计算'}
          </button>
        </div>
      </div>

      <div>
        <ul role="list" className="divide-y divide-blue-400">
          {farmingCampaigns.map((farmingCam, index) => (
            <li key={index} className="flex justify-between gap-x-6 py-3">
              <div className="flex min-w-0 gap-x-4">
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">{farmingCam.name}</p>
                </div>
              </div>

              <div className="flex shrink-0 flex-col items-end">
                <p className="text-sm leading-6 text-gray-900">{Math.ceil(farmingCam.count)}</p>
              </div>
            </li>
          ))}

          {farmingCampaigns.length > 0 ? (
            <li className="flex justify-between gap-x-6 py-3">
              <div className="flex min-w-0 gap-x-4">
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">总体力消耗</p>
                </div>
              </div>
              <div className="flex shrink-0 flex-col items-end">
                <p className="text-sm leading-6 text-gray-900">
                  {farmingCampaigns.reduce(
                    (accumulator, currentValue) => accumulator + Math.ceil(currentValue.count) * 10,
                    0,
                  )}
                </p>
              </div>
            </li>
          ) : null}
        </ul>
      </div>
    </div>
  );
}
