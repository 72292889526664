export function Feedbacks() {
  return (
    <div className="px-1">
      <div className="flex items-center justify-between">
        <div className="mb-2 text-xl font-bold"> Feedbacks </div>
      </div>

      <div>
        <p> Up 已经把游戏下载回来了，正在更新 </p>
        <p> 没时间管理群，有什么问题和建议可以发送邮件到 contact@bafarming.com </p>
        <p> 如果有好心人能提供个全通的小号做测试的话，感激不尽！ </p>
      </div>
    </div>
  );
}
