import { CAMPAIGNS, MAX_STAGE } from '@/constants/constants';
import { ItemMap } from '@/models/models';
import solver from 'javascript-lp-solver';

function constructVariables(itemsNeeded) {
  const variables = {};
  // find all campaigns that drop the needed items
  CAMPAIGNS.forEach((cam) => {
    // 暂时截止到 14
    if (cam.Area > MAX_STAGE || cam.Difficulty === 1) {
      return;
    }
    const isDropping = cam.Rewards.Default.some((loot) => {
      return itemsNeeded[(loot[0] + '').substring(1)];
    });
    if (!isDropping) {
      return;
    }
    const itemDropTable = {};
    cam.Rewards.Default.forEach((reward) => {
      itemDropTable[reward[0]] = reward[1];
    });
    variables[`Stage ${cam.Area}-${cam.Stage}`] = {
      cost: cam.EntryCost[0][1],
      ...itemDropTable,
    };
  });
  console.log(variables);
  return variables;
}

function shouldUserFarm(itemsNeeded: ItemMap, itemsOwned: ItemMap) {
  return Object.keys(itemsNeeded).some((itemId) => {
    const gap = itemsNeeded[itemId] - (itemsOwned[itemId] || 0);
    return gap > 0;
  });
}

export function calculateFarmingPlan(itemsNeeded: ItemMap, itemsOwned: ItemMap) {
  // Calculate min constraints. AKA least items we need to farm
  const userShouldFarm = shouldUserFarm(itemsNeeded, itemsOwned);
  if (!userShouldFarm) {
    return;
  }

  const constraints = {};
  for (const itemId in itemsNeeded) {
    const gap = itemsNeeded[itemId] - (itemsOwned[itemId] || 0);
    if (gap > 0) {
      // 这个地方有点奇怪，schale.gg 的 loot 表里的掉落 id 对不上，要加个前缀2
      // 先 hardcode 处理了
      constraints['2' + itemId] = { min: gap };
    }
  }

  const variables = constructVariables(itemsNeeded);

  // 暂时应该不用关注整数解了
  // const ints = {};
  // for (const variable in variables) {
  //   ints[variable] = 1;
  // }

  const model = {
    optimize: 'cost',
    opType: 'min',
    constraints,
    variables,
    // ints,
    options: {
      timeout: 5000,
      tolerance: 0.05,
    },
  };
  const results = solver.Solve(model);
  console.log(results);
  return results;
}
